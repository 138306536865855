import DEMO_EVENT from './demoEvent';
import USER_EVENT from './userEvent';
import STATUS_PAGE_EVENT from './statusPageEvent';
import PRODUCT_EVENT from './productEvent';
import DEMO_PLAY_EVENT from './demoPlayEvent';
import FAQ_EVENT from './faqEvent';
import VERSION_EVENT from './versionEvent';

const EVENTS = {
  DEMO_EVENT,
  USER_EVENT,
  STATUS_PAGE_EVENT,
  PRODUCT_EVENT,
  DEMO_PLAY_EVENT,
  FAQ_EVENT,
  VERSION_EVENT,
};

export default EVENTS;
