import DMSansMediumFont from '../../../dist/fonts/dm-sans/DMSans-Medium.ttf';

const DMSansMedium = {
  fontFamily: 'dm-sans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
    url(${DMSansMediumFont})
  `,
};

export default DMSansMedium;
