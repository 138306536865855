const PRODUCT_EVENT = {
  usageTrafficPending: 'product/usage-traffic/pending',
  usageTrafficFullfiled: 'product/usage-traffic/fullfiled',
  usageTrafficRejected: 'product/usage-traffic/rejected',
  usageTrafficReset: 'product/usage-traffic/reset',

  usageCountPending: 'product/usage-count/pending',
  usageCountFullfiled: 'product/usage-count/fullfiled',
  usageCountRejected: 'product/usage-count/rejected',
  usageCountReset: 'product/usage-count/reset',

  tokenSetProductPending: 'product/token-set/pending',
  tokenSetProductFullfiled: 'product/token-set/fullfiled',
  tokenSetProductRejected: 'product/token-set/rejected',
  tokenSetProductReset: 'product/token-set/reset',

};

export default PRODUCT_EVENT;
