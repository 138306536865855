import React from 'react';

const ContactUsScreen = React.lazy(() => import('../containers/ContactUsScreen'));

export default [
  {
    component: ContactUsScreen,
    path: '/contact-us',
    exact: true,
  },
];
