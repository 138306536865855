import axios from 'axios';

export function getDataIp() {
  return new Promise((resolve, reject) => {
    axios.get('https://geolocation-db.com/json/')
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export const a = '';
