import React from 'react';

const DashboardScreen = React.lazy(() => import('../containers/DashboardScreen'));

export default [
  {
    component: DashboardScreen,
    path: '/dasboard/pricing',
    exact: true,
  },
  {
    component: DashboardScreen,
    path: '/dasboard/api-and-service',
    exact: true,
  },
  {
    component: DashboardScreen,
    path: '/dasboard/billing-dashboard',
    exact: true,
  },
  {
    component: DashboardScreen,
    path: '/dasboard/documentation',
    exact: true,
  },
  {
    component: DashboardScreen,
    path: '/dasboard/setting-account',
    exact: true,
  },
];
