import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  contentDropdown: {
    background: 'transparent',
    border: '0px',
    '&:before': {
      height: '0 !important',
    },
  },
  topContent: {
    '&.Mui-expanded': {
      margin: '0',
    },
  },
  activeMenu: {
    fontWeight: 600,
  },
});

export default styles;
