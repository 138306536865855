/* eslint-disable prefer-const */
/* eslint-disable import/no-mutable-exports */
import axios from 'axios';
import { resetStatusPage } from '../actions/statusPageActions';
import REDUX from '../redux/store';

let EX_AXIOS_BASE;
const middlewareBeforeRequest = (config) => {
  const localStorage = JSON.parse(window.localStorage['persist:root']);
  const status = JSON.parse(localStorage.StatusPageReducer);
  let newConfig = { ...config };
  if (!config.headers.Authorization) {
    // eslint-disable-next-line no-unused-vars
    newConfig = {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${status.token}`,
      },
    };
  }
  newConfig.timeout = 3000;
  return newConfig;
};
const middlewareRejectResponse = (res) => {
  // console.log(JSON.stringify(res), 'hilmi');
  if (res?.response ?? false) {
    return Promise.reject(res);
  }
  if (res?.data?.message === 'unauthorized') {
    REDUX.store.dispatch(resetStatusPage());
    window.location.href = '/';
    return Promise.reject(res);
  }
  return Promise.resolve(res);
};
let AXIOS_BASE = {};
if (process.env.NODE_ENV === 'development') {
  AXIOS_BASE.API = axios.create({
    baseURL: `${process.env.REACT_APP_IP_SERVICES}`,
    timeout: 3000,
  });
  // AXIOS_BASE.BATCH = axios.create({
  //   baseURL: `http://${process.env.REACT_APP_URL_BATCH}`,
  // });
  AXIOS_BASE.API.interceptors.request.use(middlewareBeforeRequest);
  AXIOS_BASE.API.interceptors.response.use(middlewareRejectResponse, middlewareRejectResponse);
} else {
  AXIOS_BASE.API = axios;
  AXIOS_BASE.API.interceptors.request.use(middlewareBeforeRequest);
  AXIOS_BASE.API.interceptors.response.use(middlewareRejectResponse, middlewareRejectResponse);
}
EX_AXIOS_BASE = AXIOS_BASE;
export default EX_AXIOS_BASE;
