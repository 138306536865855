import AXIOS_BASE from '../config';
import { PATH } from '../prefix';

const action = {};

action.graph = (values) => AXIOS_BASE.API.get(`${PATH}/graph?uuid=${values.uuid}`);
action.rate = (values) => AXIOS_BASE.API.get(`${PATH}/rate?uuid=${values.uuid}`);
action.createToken = (values) => AXIOS_BASE.API.post(`${PATH}/createtoken`, { uuid: values.uuid });
action.getToken = (values) => AXIOS_BASE.API.get(`${PATH}/gettoken`, { uuid: values.uuid });

export default action;
