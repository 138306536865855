/* eslint-disable no-useless-computed-key */
import { createTheme, responsiveFontSizes } from '@mui/material';
import Fonts from './fonts';
import Colors from './colors';

// A custom theme for this app
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 360,
      md: 1024,
      lg: 1440,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: Colors.primary,
    },
    secondary: {
      main: Colors.secondary,
    },
    error: {
      main: Colors.colorErrorInput,
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    htmlFontSize: 16,
    allVariants: {
      fontFamily: 'dm-sans',
    },
    body1: {
      fontWeight: 400,
      fontSize: '0.9rem',
    },
    body2: {
      fontWeight: 600,
      fontSize: '0.9rem',
    },
    h1: {
      fontWeight: 800,
    },
    h2: {
      fontWeight: 800,
    },
    h3: {
      fontWeight: 800,
    },
    h4: {
      fontWeight: 800,
    },
    h5: {
      fontWeight: 800,
    },
    h6: {
      fontWeight: 800,
    },
    subtitle1: {
      fontWeight: 800,
    },
    subtitle2: {
      fontWeight: 600,
    },
    caption: {
      fontWeight: 600,
      lineHeight: '45px',
    },

  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          scrollBehavior: 'smooth',
        },
        ':root': {
          ['@media (max-width:360px)']: {
            fontSize: '12px',
          },
          ['@media (min-width:361px) and (max-width:1024px)']: {
            fontSize: '14px',
          },
          fontSize: '16px',
          '@font-face': [Fonts.dmSans.FontBold, Fonts.dmSans.FontMedium, Fonts.dmSans.FontRegular],
          body: {
            backgroundColor: Colors.background,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: Colors.colorLink,
          textDecorationColor: Colors.colorLink,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '9999px',
        },
        contained: {
          textTransform: 'none',
          boxShadow: '1.54036px 3.08071px 8.47196px rgba(99, 9, 17, 0.27)',
          '&:hover': {
            // boxShadow: 'none',
          },
          '&:focus': {
            boxShadow: '1.54036px 3.08071px 8.47196px rgba(99, 9, 17, 0.27)',
          },
        },
        disableElevation: true,
      },
    },
  },
});

const newTheme = responsiveFontSizes(theme);
export default newTheme;
