/* eslint-disable import/prefer-default-export */
import EVENTS from '../events';

const stateReducer = {
  isLoading: false,
  error: false,
  data: [],
};

export function ListFaqReducer(state = stateReducer, action) {
  switch (action.type) {
    case EVENTS.FAQ_EVENT.listFaqPending:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case EVENTS.FAQ_EVENT.listFaqFulfilled:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case EVENTS.FAQ_EVENT.listFaqRejected:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    default:
      return state;
  }
}
