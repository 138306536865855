import { PATH_GOOGLE_SERVICE } from '../prefix';

const action = {};
action.contactUsPost = (values) => {
  const formData = new FormData();
  formData.set('TIMESTAMP', values.timestamp);
  formData.set('STATUS', values.status);
  formData.set('NAME', values.name);
  formData.set('EMAIL', values.email);
  formData.set('PHONE', values.phone);
  formData.set('EKYC', values.ekyc);
  formData.set('FACEID', values.faceid);
  formData.set('RECOD', values.recod);
  formData.set('MESSAGE', values.message);
  return fetch(`${PATH_GOOGLE_SERVICE}exec`, {
    method: 'post',
    body: formData,
  });
};

export default action;
