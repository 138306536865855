import EVENTS from '../events';

const stateReducer = {
  isLoading: false,
  error: false,
  data: [],
};
const stateObjectReducer = {
  isLoading: false,
  error: false,
  data: '',
};
export function UsageTrafficReducer(state = stateReducer, action) {
  switch (action.type) {
    case EVENTS.PRODUCT_EVENT.usageTrafficPending:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case EVENTS.PRODUCT_EVENT.usageTrafficFullfiled:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case EVENTS.PRODUCT_EVENT.usageTrafficRejected:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case EVENTS.PRODUCT_EVENT.usageTrafficReset:
      return {
        ...state,
        isLoading: false,
        data: [],
        error: true,
      };
    default:
      return state;
  }
}
export function GenerateReducer(state = stateObjectReducer, action) {
  switch (action.type) {
    case EVENTS.PRODUCT_EVENT.tokenSetProductPending:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case EVENTS.PRODUCT_EVENT.tokenSetProductFullfiled:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case EVENTS.PRODUCT_EVENT.tokenSetProductRejected:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case EVENTS.PRODUCT_EVENT.tokenSetProductReset:
      return {
        ...state,
        isLoading: false,
        data: [],
        error: true,
      };
    default:
      return state;
  }
}
export function UsageCountReducer(state = stateReducer, action) {
  switch (action.type) {
    case EVENTS.PRODUCT_EVENT.usageCountPending:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case EVENTS.PRODUCT_EVENT.usageCountFullfiled:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case EVENTS.PRODUCT_EVENT.usageCountRejected:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case EVENTS.PRODUCT_EVENT.usageCountReset:
      return {
        ...state,
        isLoading: false,
        data: [],
        error: true,
      };
    default:
      return state;
  }
}
