import AXIOS_BASE from '../config';
import { PATH } from '../prefix';

const action = {};

action.login = (values) => AXIOS_BASE.API.post(`${PATH}/login`, {
  email: values.email,
  password: values.password,
});
action.register = (values) => AXIOS_BASE.API.post(`${PATH}/register`, {
  username: values.username,
  password: values.password,
  name: values.name,
  email: values.email,
  company: values.company,
});
action.user = (values) => AXIOS_BASE.API.post(`${PATH}/user?token=${values.token}`);
action.activated = (values) => AXIOS_BASE.API.post(`${PATH}/activated`, {
  email: values.email,
});
action.activated = (values) => AXIOS_BASE.API.post(`${PATH}/activated`, {
  email: values.email,
});
action.changepassword = (values) => AXIOS_BASE.API.post(`${PATH}/changepassword`, {
  uuid: values.uuid,
  oldPassword: values.oldPassword,
  newPassword: values.newPassword,
});
action.logout = () => AXIOS_BASE.API.post(`${PATH}/logout`);

export default action;
