/* eslint-disable no-unused-vars */
import {
  Route,
  Switch,
  Redirect,
  useRouteMatch,
  useLocation,
} from 'react-router-dom';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import RootWeb from './RootWeb';
import LoginWeb from './LoginWeb';
import RegisterWeb from './RegisterWeb';
import ServiceDescWeb from './ServiceDescWeb';
import DasboardWeb from './DasboardWeb';
import ContactUsWeb from './ContactUsWeb';
import FaqWeb from './FaqWeb';
import pageViewVariable from '../variables/googleAnalytics/pageViewVariable';
import googleAnalyticsActions, { matchingTrackingPath } from '../actions/googleAnalyticsActions';
import { demoPlayResetProductAction } from '../actions/demoPlayActions';

const routes = [
  ...RootWeb,
  ...LoginWeb,
  ...RegisterWeb,
  ...ServiceDescWeb,
  ...DasboardWeb,
  ...ContactUsWeb,
  ...FaqWeb,
];
const Web = () => {
  const routeMatch = useRouteMatch();
  const location = useLocation();

  const { DemoPlayReducer } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    // first inisiate GA
    ReactGA.initialize(process.env.REACT_APP_GA_TRAFFIC_ID);
    // checking path match tracker;
    const dataMatch = matchingTrackingPath(pageViewVariable, location.pathname);
    // action for google analytics
    googleAnalyticsActions(dataMatch.type, dataMatch.property, location.pathname);

    // action inital state count demo
    Object.entries(DemoPlayReducer.data).forEach(([key, data]) => {
      if (data.rehitTime) {
        const test = moment(data.rehitTime).add(1, 'day');
        if (moment(new Date()).isAfter(test)) {
          dispatch(demoPlayResetProductAction(key));
        }
      }
    });

    // check version cookie
  }, [DemoPlayReducer.data, dispatch, location.pathname, routeMatch]);
  return (
    <>
      {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
      <Switch>
        {
          routes.map(({ component: Component, ...props }, index) => (
            <Route {...props} key={index} component={Component} />
          ))
        }
        <Route path="*">
          <Redirect to="/404" />
        </Route>
      </Switch>
    </>
  );
};

export default Web;
