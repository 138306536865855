/* eslint-disable import/prefer-default-export */
const pathList = {
  production: '/web-api',
  development: '',
  test: '',
};
const pathGoogleService = {
  development: `https://script.google.com/macros/s/${process.env.REACT_APP_SCRIPT_ID_GOOGLE}/`,
  production: '/script-google/',
  test: `https://script.google.com/macros/s/${process.env.REACT_APP_SCRIPT_ID_GOOGLE}/`,
};
export const PATH = pathList[process.env.NODE_ENV];
export const PATH_STORAGE = process.env.NODE_ENV === 'development' ? 'https://ihsmartbucket.s3.ap-southeast-1.amazonaws.com/jalin/video/' : '/storage-aws/jalin/video/';
export const PATH_GOOGLE_SERVICE = pathGoogleService[process.env.NODE_ENV];
