/* eslint-disable no-unused-vars */
import React, { Suspense, useEffect } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { CssBaseline, ThemeProvider } from '@mui/material';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import theme from './themes';
import Web from './web';
import LoadingComponent from './components/LoadingComponent';
import REDUX from './redux/store';
import { changeFavicon, changeTitlePage } from './utils/documentUtils';
import Favicon from './dist/favicon.ico';
import { initalSetIp } from './actions/demoPlayActions';

const App = () => {
  changeFavicon(Favicon);
  changeTitlePage('Recod');
  useEffect(() => {
    REDUX.store.dispatch(initalSetIp());
  }, []);

  return (
		<ThemeProvider theme={theme}>
			{/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
			<CssBaseline />
			<Suspense fallback={<LoadingComponent />}>
				<Provider store={REDUX.store}>
					<PersistGate loading={null} persistor={REDUX.persistor}>
						<Router>
							<Web />
						</Router>
					</PersistGate>
				</Provider>
			</Suspense>
		</ThemeProvider>
  );
};

export default App;
