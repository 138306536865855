import { Button, ThemeProvider } from '@mui/material';
import React from 'react';
import innerTheme from './theme';

const CustomContainedButton = ({
  buttonColor, textColor, border, boxShadow, ...muiButtonProps
}) => <>
    <ThemeProvider theme={
      (theme) => innerTheme(theme, buttonColor, textColor, border, boxShadow)}>
      <Button variant="contained" color="primary" {...muiButtonProps} />
    </ThemeProvider>
  </>;

export default CustomContainedButton;
