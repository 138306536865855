import { Box } from '@mui/material';
import React from 'react';
import LoadingComponent from '../Loading';

const LoadingContentComponent = () => <>
  <Box
    position="fixed"
    top="0"
    bottom="0"
    right="0"
    left="0"
    display="flex"
    justifyContent="center"
    alignItems="center"
  >
    <LoadingComponent size="200px" />
  </Box>
</>;

export default LoadingContentComponent;
