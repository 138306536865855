import { createTheme } from '@mui/material';

const innerTheme = (theme) => createTheme({
  ...theme,
  components: {
    ...theme.components,
    MuiButton: {
      ...theme.components.MuiButton,
      styleOverrides: {
        ...theme.components.MuiButton.styleOverrides,
        contained: {
          textTransform: 'none',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
          '&:focus': {
            boxShadow: 'none',
          },
        },
      },
    },
  },
});

export default innerTheme;
