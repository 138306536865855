import React from 'react';
import S404Screen from '../containers/404Screen';

const LandingScreen = React.lazy(() => import('../containers/LandingScreen'));
const VerificationAccountScreen = React.lazy(() => import('../containers/VerificationAccountScreen'));
// const S404Screen = React.lazy(() => import('../containers/404Screen'));

export default [
  {
    component: LandingScreen,
    path: '/',
    exact: true,
  },
  {
    component: S404Screen,
    path: '/404',
    exact: true,
  },
  {
    component: VerificationAccountScreen,
    path: '/verification-account',
    exact: true,
  },
];
