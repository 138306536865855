import { createTheme } from '@mui/material';

const innerTheme = (theme) => createTheme({
  ...theme,
  components: {
    ...theme.components,
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            background: `${theme.palette.primary.main}22`,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontWeight: '600 !important',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#909090',
        },
      },
    },
  },
});

export default innerTheme;
