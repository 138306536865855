/* eslint-disable react/prop-types */
import React from 'react';
import { Button } from '@mui/material';
import SecondaryButton from './Components/SecondaryButton';
import CustomeContainedButton from './Components/CustomContainedButton';
import PrimaryFilter from './Components/PrimaryFilter';
import SecondaryFilter from './Components/SecondaryFilter';

function ButtonComponent({ variant, ...muiButtonProps }) {
  if (variant === 'secondaryButton') {
    return <SecondaryButton {...muiButtonProps} />;
  }
  if (variant === 'primaryFilter') {
    return <PrimaryFilter {...muiButtonProps} />;
  }
  if (variant === 'secondaryFilter') {
    return <SecondaryFilter {...muiButtonProps} />;
  }
  if (variant === 'customeContainedButton') {
    return <CustomeContainedButton {...muiButtonProps} />;
  }
  return <Button variant={variant} {...muiButtonProps} />;
}

export default ButtonComponent;
