import EVENTS from '../events';

const defaultDemoState = {
  count: 0,
  rehitTime: new Date(),
};
const stateReducer = {
  data: {
    od: defaultDemoState,
    vc: defaultDemoState,
  },
  ip: false,
};
function DemoPlayReducer(state = stateReducer, action) {
  switch (action.type) {
    case EVENTS.DEMO_PLAY_EVENT.demoPlaySetProduct:
      return { ...state, data: { ...state.data, [action.product]: action.data } };
    case EVENTS.DEMO_PLAY_EVENT.demoPlayResetProduct:
      return { ...state, data: { ...state.data, [action.product]: defaultDemoState } };
    case EVENTS.DEMO_PLAY_EVENT.demoPlaySetIp:
      return { ...state, ip: action.ip };
    case EVENTS.DEMO_PLAY_EVENT.demoPlayReset:
      return stateReducer;
    default:
      return state;
  }
}

export default DemoPlayReducer;
