import React from 'react';

const LoginScreen = React.lazy(() => import('../containers/LoginScreen'));

export default [
  {
    component: LoginScreen,
    path: '/login',
    exact: true,
  },
];
