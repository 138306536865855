import AXIOS_BASE from '../config';
import { PATH } from '../prefix';

const action = {};

export const makeid = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

action.demoObjectDetection = (values) => {
  const fileext = values.file.type.split('/')[1];
  const formData = new FormData();
  formData.set('msisdn', values.msisdn);
  formData.set('id_camera', values.id_camera);
  formData.set('timestamp', values.timestamp);
  formData.append('file', values.file, `result_object_detection_${makeid(10)}.${fileext}`);
  formData.set('nd', values.nd);
  formData.set('object_list', values.object_list);
  formData.set('confidence', values.confidence);
  formData.set('label', values.label);
  formData.set('threshold', values.threshold);
  formData.set('image', values.image);
  formData.set('uuid', values.uuid);
  formData.set('feature', 'object_detection');
  formData.set('token', values.token);
  return AXIOS_BASE.API.post(`${PATH}/uploadfile`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

action.demoVehicleCounting = (values) => {
  const fileext = values.file.type.split('/')[1];
  const formData = new FormData();
  formData.set('msisdn', values.msisdn);
  formData.set('id_camera', values.id_camera);
  formData.set('timestamp', values.timestamp);
  formData.append('file', values.file, `result_vehicle_counting_${makeid(10)}.${fileext}`);
  formData.set('nd', values.nd);
  formData.set('object_list', values.object_list);
  formData.set('confidence', values.confidence);
  formData.set('label', values.label);
  formData.set('threshold', values.threshold);
  formData.set('image', values.image);
  formData.set('uuid', values.uuid);
  formData.set('feature', 'vehicle_counting');
  formData.set('token', values.token);
  return AXIOS_BASE.API.post(`${PATH}/uploadfile`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

action.demoVehicleCounting = (values) => {
  const formData = new FormData();
  formData.set('msisdn', values.msisdn);
  formData.set('id_camera', values.id_camera);
  formData.set('timestamp', values.timestamp);
  formData.append('file', values.file);
  formData.set('nd', values.nd);
  formData.set('object_list', values.object_list);
  formData.set('confidence', values.confidence);
  formData.set('label', values.label);
  formData.set('threshold', values.threshold);
  formData.set('image', values.image);
  formData.set('uuid', values.uuid);
  formData.set('feature', 'vehicle_counting');
  formData.set('token', values.token);
  return AXIOS_BASE.API.post(`${PATH}/uploadfile`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

action.demoCrowdDetection = (values) => {
  const formData = new FormData();
  formData.set('msisdn', values.msisdn);
  formData.set('id_camera', values.id_camera);
  formData.set('timestamp', values.timestamp);
  formData.append('file', values.file);
  formData.set('nd', values.nd);
  formData.set('object_list', values.object_list);
  formData.set('confidence', values.confidence);
  formData.set('label', values.label);
  formData.set('threshold', values.threshold);
  formData.set('image', values.image);
  formData.set('uuid', values.uuid);
  formData.set('feature', 'crowd_detection');
  formData.set('token', values.token);
  return AXIOS_BASE.API.post(`${PATH}/uploadfile`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export default action;
