import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Fade,
  Grid,
  Link,
  Slide,
  Stack,
  ThemeProvider,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Button from '../Button';
import Logo from '../../dist/images/logo-right.png';
import Account from '../../dist/icons/ic_account.svg';
import colors from '../../themes/colors';
import Dropdown from '../Dropdown';
import style from './style';
import innerTheme from './theme';
import { logoutAction } from '../../actions/userActions';
import IcLogout from '../../dist/icons/Ic_logout.svg';
import IcDashboard from '../../dist/icons/ic_dashboard.svg';
import IcBurger from '../../themes/icons/icBurger';
import PopMenu from './components/PopMenu';

function NavbarItem({
  enterPopMenu,
  setEnterPopMenu,
}) {
  const classes = style();
  const dispatch = useDispatch();
  const StatusPageReducer = useSelector((state) => (state.StatusPageReducer));
  const history = useHistory();
  const isHome = history.location.pathname === '/';
  const isProduct = history.location.pathname.includes('service-desc');
  const isContact = history.location.pathname.includes('contact-us');
  const isFaq = history.location.pathname.includes('faq');
  const toPage = useCallback((path) => {
    history.push(path);
  }, [history]);
  const handleLogout = useCallback(() => {
    dispatch(logoutAction());
  }, [dispatch]);
  const handleToDocumentation = useCallback(() => {
    window.open('https://picaso-recod.gitbook.io/recod-product-documentation', '_blank').focus();
  }, []);
  const themeComponent = useTheme();
  const isTabletDown = useMediaQuery(themeComponent.breakpoints.down('md'));
  return <>
    <Box width={'100%'} maxWidth={isProduct ? null : '1536px'} margin={'auto'} style={{
      transition: '.2s ease',
    }}>
      <Stack container spacing={1} alignItems="center" direction={'row'} pl={isTabletDown ? 3 : 0} pr={isTabletDown ? 1 : 0}>
        <Box display='flex' width={'275px'} alignItems="center" justifyContent={isTabletDown ? '' : 'center'} >
          <img loading="lazy" src={Logo} alt="" className={classes.logo} onClick={() => history.replace('/')}/>
        </Box>
        <Stack direction={'row'} flex="1" >
            {
            !isTabletDown ? <>
              <Box flex={1}>
                <ThemeProvider theme={(theme) => innerTheme(theme)}>
                  <Box display='flex' justifyContent="center" alignItems="center">
                    <Link
                      color={'secondary'}
                      component="button"
                      href="#!"
                      underline="hover"
                      onClick={() => toPage('/')}
                      className={`${classes.linkStyles} ${isHome ? 'active' : ''}`}
                      >
                      <Typography >
                        Home
                      </Typography>
                    </Link>
                    <Dropdown
                      data={[
                        {
                          text: 'Object Detection',
                          onClick: () => {
                            toPage('/service-desc/object-detection');
                          },
                        },
                        {
                          text: 'Vehicle Counting',
                          onClick: () => {
                            toPage('/service-desc/vehicle-counting');
                          },
                        },
                        {
                          text: 'People Counting',
                          onClick: () => {
                            toPage('/service-desc/people-counting');
                          },
                        },
                        {
                          text: 'Crowd Detection',
                          onClick: () => {
                            toPage('/service-desc/crowd-detection');
                          },
                        },
                        {
                          text: 'License Plate Recognition',
                          onClick: () => {
                            toPage('/service-desc/license-plate-recoognition');
                          },
                        },
                        {
                          text: 'Traffic Measurement',
                          onClick: () => {
                            toPage('/service-desc/traffic-measurement');
                          },
                        },
                      ]}
                    >
                      <Link
                        color={'secondary'}
                        component="button"
                        underline="hover"
                        href="#!"
                        className={`${classes.linkStyles} ${isProduct ? 'active' : ''}`}
                        >
                        <Typography >
                          Product
                        </Typography>
                      </Link>
                    </Dropdown>
                    <Link
                      color={'secondary'}
                      component="button"
                      underline="hover"
                      href="#!"
                      className={classes.linkStyles}
                      onClick={handleToDocumentation}
                      >
                      <Typography >
                        Documentation
                      </Typography>
                    </Link>
                    <Link
                      color={'secondary'}
                      component="button"
                      underline="hover"
                      onClick={() => toPage('/faq')}
                      href="#!"
                      className={`${classes.linkStyles} ${isFaq ? 'active' : ''}`}
                      >
                      <Typography >
                        FAQ
                      </Typography>
                    </Link>
                    <Link
                      color={'secondary'}
                      component="button"
                      underline="hover"
                      href="#!"
                      onClick={() => toPage('/contact-us')}
                      className={`${classes.linkStyles} ${isContact ? 'active' : ''}`}
                      >
                      <Typography >
                        Contact Us
                      </Typography>
                    </Link>
                  </Box>
                </ThemeProvider>
              </Box>
              <Box width={'100%'} maxWidth="350px" mr={isProduct ? 3 : 0} >
                <Box display='flex' justifyContent="flex-end" alignItems="center">
                  {
                    StatusPageReducer.token
                      ? (
                      <Dropdown
                        data={[
                          {
                            text: <>
                              <img loading="lazy" alt="" src={IcDashboard}/>
                              <Typography variant="inherit" marginLeft="10px">Dasboard</Typography>
                            </>,
                            onClick: () => history.push('/dasboard/api-and-service'),
                          },
                          {
                            text: <>
                              <img loading="lazy" alt="" src={IcLogout}/>
                              <Typography variant="inherit" marginLeft="10px">Logout</Typography>
                            </>,
                            onClick: handleLogout,
                          },
                        ]}
                        withDevide={true}
                        withoutArrow={true}
                      >
                        <Tooltip title={StatusPageReducer.data.activated.email} placement='bottom' classes={{
                          tooltip: classes.popperTooltips,
                        }}>
                          <img loading="lazy" src={Account} alt=""
                            className={classes.pointerStyles}/>
                        </Tooltip>
                      </Dropdown>
                      )
                      : (
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <Button variant="contained" color="primary" size={'large'} fullWidth onClick={() => toPage('/register')}>
                            Register
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button variant="secondaryButton" color="primary" size={'large'} fullWidth onClick={() => toPage('/login')}>
                            Login
                          </Button>
                        </Grid>
                      </Grid>
                      )
                  }
                </Box>
              </Box>
            </>
              : <>
                <Grid item xs={12}>
                  <Box display={'flex'} justifyContent="flex-end">
                    <Button onClick={() => setEnterPopMenu(true)} name="menu">
                      <IcBurger htmlColor="#000"/>
                    </Button>
                  </Box>
                  <PopMenu enter={enterPopMenu} setEnter={setEnterPopMenu}/>
                </Grid>
              </>
            }
        </Stack>
      </Stack>
    </Box>
  </>;
}

function Navbar({ defaultFix = false, onlyFix = false, defaultStatic = false }) {
  const [isFix, setFix] = useState(defaultFix);
  const [enterPopMenu, setEnterPopMenu] = useState(false);
  const handleScroll = () => {
    setEnterPopMenu(false);
    if (window.scrollY > 100) {
      setFix(true);
    } else {
      setFix(false);
    }
    return null;
  };
  useEffect(() => {
    if (!onlyFix && !defaultStatic) {
      window.addEventListener('scroll', handleScroll, { passive: true });
      return () => window.removeEventListener('scroll', () => {});
    }
    return () => {};
  }, [defaultStatic, onlyFix]);

  if (defaultStatic) {
    return <Box
      bgcolor={colors.white}
      minHeight={'80px'}
      display="flex"
      alignItems="center"
      boxShadow="0px 4px 4px rgba(215, 235, 243, 0.25)"
      zIndex="1000"
      position="relative"
    >
      <NavbarItem enterPopMenu={enterPopMenu} setEnterPopMenu={setEnterPopMenu}/>
    </Box>;
  }
  if (defaultFix) {
    return <>
      <Box
        minHeight={'80px'}
      >
      </Box>
      <Box
        minHeight="80px"
        position="fixed"
        top="0"
        left="0"
        right="0"
        display="flex"
        alignItems="center"
        bgcolor={colors.white}
        zIndex="10"
        boxShadow="-3px 12px 22px -14px rgba(215, 235, 243, 0.4)"
      >
        <NavbarItem enterPopMenu={enterPopMenu} setEnterPopMenu={setEnterPopMenu}/>
      </Box>
    </>;
  }
  return <>
    <Fade in={!isFix}>
      <Box
        width={'100%'}
        bgcolor={colors.white}
        minHeight={'80px'}
        display="flex"
        alignItems="center"
        zIndex="1000"
        position="relative"
        boxShadow={'0px 4px 4px rgba(215, 235, 243, 0.25)'}
      >
        <NavbarItem enterPopMenu={enterPopMenu} setEnterPopMenu={setEnterPopMenu}/>
      </Box>
    </Fade>
    <Slide in={isFix} direction="down">
      <Box
        width={'100%'}
        minHeight="80px"
        position="fixed"
        top="0"
        left="0"
        right="0"
        display="flex"
        alignItems="center"
        bgcolor={colors.white}
        zIndex="10"
        boxShadow="-3px 12px 22px -14px rgba(215, 235, 243, 0.4)"
      >
        <NavbarItem enterPopMenu={enterPopMenu} setEnterPopMenu={setEnterPopMenu}/>
      </Box>
    </Slide>
  </>;
}

export default Navbar;
