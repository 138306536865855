/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ClickAwayListener,
  // ClickAwayListener,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Slide,
  Typography,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import IcClose from '../../../themes/icons/icClose';
import Button from '../../Button';
import styles from './styles';
import Account from '../../../dist/icons/ic_account.svg';
import IcDashboard from '../../../dist/icons/ic_dashboard.svg';
import IcLogout from '../../../dist/icons/Ic_logout.svg';
import { logoutAction } from '../../../actions/userActions';

const PopMenu = ({
  enter,
  setEnter,
}) => {
  const history = useHistory();
  const toPage = useCallback((path) => {
    setEnter(false);
    history.push(path);
  }, [history, setEnter]);
  const classes = styles();
  const dataService = [
    {
      text: 'Object Detection',
      onClick: () => {
        toPage('/service-desc/object-detection');
      },
    },
    {
      text: 'Vehicle Counting',
      onClick: () => {
        toPage('/service-desc/vehicle-counting');
      },
    },
    {
      text: 'People Counting',
      onClick: () => {
        toPage('/service-desc/people-counting');
      },
    },
    {
      text: 'Crowd Detection',
      onClick: () => {
        toPage('/service-desc/crowd-detection');
      },
    },
    {
      text: 'License Plate Recognition',
      onClick: () => {
        toPage('/service-desc/license-plate-recoognition');
      },
    },
    {
      text: 'Traffic Measurement',
      onClick: () => {
        toPage('/service-desc/traffic-measurement');
      },
    },
  ];
  const [enterSlide, setEnterSlide] = useState(false);
  const handleClose = useCallback((event) => {
    setEnter(false);
    // setEnterSlide(false);
  }, [setEnter]);
  const [openDropdown, setOpenDropdown] = useState(false);
  const handleToggleDropdown = useCallback(() => {
    setOpenDropdown(!openDropdown);
  }, [openDropdown]);

  const isHome = history.location.pathname === '/';
  const isProduct = history.location.pathname.includes('service-desc');
  const isContact = history.location.pathname.includes('contact-us');
  const isFaq = history.location.pathname.includes('faq');

  const StatusPageReducer = useSelector((state) => (state.StatusPageReducer));
  const dispatch = useDispatch();
  const handleLogout = useCallback(() => {
    dispatch(logoutAction());
  }, [dispatch]);

  const handleToDocumentation = useCallback(() => {
    window.open('https://picaso-recod.gitbook.io/recod-product-documentation', '_blank').focus();
  }, []);
  return <>
    <Slide direction='left' in={enter} onEntered={() => setEnterSlide(true)}>
      <Box
        position={'fixed'}
        right={0}
        top={0}
        bottom={0}
        width={'100%'}
        maxWidth="383px"
        bgcolor={'white'}
        boxShadow="-3px 4px 12px rgba(184, 184, 184, 0.25)"
        zIndex={100000}
        padding="0 24px"
      >
        <Box height={'80px'} display="flex" alignItems={'center'} justifyContent="flex-end">
          <Button onClick={handleClose}>
            <IcClose htmlColor="#000"/>
          </Button>
        </Box>
        {
          StatusPageReducer.token && <>
            <Box position={'absolute'} bottom={0} right={0} >
              <Button onClick={handleLogout}>
                <img loading="lazy" alt="" src={IcLogout} style={{ padding: '20px' }}/>
              </Button>
            </Box>
          </>
        }
        <Box maxWidth={'270px'}>
          {
              StatusPageReducer.token
                ? (
                  <Box display={'flex'} alignItems='center' gap={'20px'}>
                    <img loading="lazy" src={Account} alt=""
                        className={classes.pointerStyles}/>
                    <Typography variant='h6'>{StatusPageReducer.data.username}</Typography>
                    <img loading="lazy" alt="" src={IcDashboard}/>
                  </Box>
                ) : (
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Button variant="contained" color="primary" size={'large'} fullWidth onClick={() => toPage('/register')}>
                        Register
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button variant="secondaryButton" color="primary" size={'large'} fullWidth onClick={() => toPage('/login')}>
                        Login
                      </Button>
                    </Grid>
                  </Grid>
                )
          }
          <List component="nav" >
            <ListItemButton
              // onClick={(event) => handleListItemClick(event, 0)}
            >
              <ListItemText primary="Home" classes={{
                primary: isHome && classes.activeMenu,
              }}
              onClick={() => toPage('/')}
              />
            </ListItemButton>
            <Divider />
            <Accordion
              expanded={openDropdown}
              classes={{
                root: classes.contentDropdown,
              }}
              variant='outlined' >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                onClick={handleToggleDropdown}
                classes={{
                  content: classes.topContent,
                }}
              >
                <Typography classes={{
                  root: isProduct && classes.activeMenu,
                }}>Product</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List component="nav" >
                  {
                    dataService.map((data, index) => (
                      <ListItemButton
                        key={index}
                        // selected={selectedIndex === 0}
                        onClick={() => data.onClick()}
                      >
                        <ListItemText primary={data.text} />
                      </ListItemButton>
                    ))
                  }
                </List>
              </AccordionDetails>
            </Accordion>
            <Divider />
            <ListItemButton
              // selected={selectedIndex === 1}
              // onClick={(event) => handleListItemClick(event, 1)}
            >
              <ListItemText primary="Documentations" onClick={handleToDocumentation} />
            </ListItemButton>
            <Divider />
            <ListItemButton
              // selected={selectedIndex === 1}
              onClick={() => toPage('/faq')}
              // onClick={(event) => handleListItemClick(event, 1)}
            >
              <ListItemText primary="FAQ" classes={{
                primary: isFaq && classes.activeMenu,
              }} />
            </ListItemButton>
            <Divider />
            <ListItemButton
              // selected={selectedIndex === 1}
              onClick={() => toPage('/contact-us')}
            >
              <ListItemText primary="Contact us" classes={{
                primary: isContact && classes.activeMenu,
              }} />
            </ListItemButton>
          </List>
        </Box>
      </Box>
    </Slide>
  </>;
};

export default PopMenu;
