import ReactGA from 'react-ga4';

export const pageViewTracking = (name, path) => {
  ReactGA.send({ hitType: name, page: path });
};

export const eventTracking = (obj) => {
  ReactGA.event(obj);
};

export const matchingTrackingPath = (data, path) => data.find((da) => (da.path === path));

export default (name, property, path) => {
  switch (name) {
    case 'page':
      pageViewTracking(property, path);
      return 'tracked';
    case 'event':
      eventTracking(property);
      return 'tracked';
    default:
      return 'not in categories';
  }
};
