export default {
  background: '#FAFDFE',
  colorTextBanner: '#fff',
  primary: '#BF303D',
  secondary: '#2E2E2E',
  colorTextRecod: '#3E3E3E',
  white: '#fff',
  colorLink: '#0087D8',
  colorFocusInput: '#0087D8',
  colorBlue: '#0087D8',
  colorErrorInput: 'rgba(191, 48, 61, 0.5)',
  colorBoxBlue: 'rgba(0, 135, 216, 0.23)',
  colorTextBoxBlue: '#00639E',
  colorInputForm: '#f5f5f5',
  colorCodeViewLight: '#f5f5f5',
  colorCodeViewDark: '#2F3C48',
  colorGrey: '#C4C4C4',
};
