import { Box } from '@mui/material';
import React from 'react';
import styles from './style';

const LoadingComponent = ({ size }) => {
  const classes = styles();
  return <>
    <Box
      width={size}
      height={size}
      className={classes.wrapperStyle}
    >
      <Box
        className={classes.wrapperCircle}
      >
      </Box>
      <Box
        className={classes.circle}
      >
      </Box>
    </Box>
  </>;
};

export default LoadingComponent;
