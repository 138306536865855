import { createTheme } from '@mui/material';

const innerTheme = (theme, btnColor, color, border, boxShadow) => createTheme({
  ...theme,
  components: {
    ...theme.components,
    MuiButton: {
      ...theme.components.MuiButton,
      styleOverrides: {
        ...theme.components.MuiButton.styleOverrides,
        containedPrimary: {
          background: `${btnColor} !important`,
          color: `${color} !important`,
          boxShadow: `${boxShadow || 'none'} !important`,
          border: `${border} !important`,
        },
      },
    },
  },
});

export default innerTheme;
