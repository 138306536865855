import moment from 'moment';
import EVENTS from '../../redux/events';
import { getDataIp } from '../../utils/ipUtils';

export function demoPlaySetProductAction(data, product) {
  return (dispatch) => {
    dispatch({
      type: EVENTS.DEMO_PLAY_EVENT.demoPlaySetProduct,
      data,
      product,
    });
  };
}
export function demoPlayResetProductAction(product) {
  return (dispatch) => {
    dispatch({
      type: EVENTS.DEMO_PLAY_EVENT.demoPlayResetProduct,
      product,
    });
  };
}
export function demoPlaySetIp(ip) {
  return (dispatch) => {
    dispatch({
      type: EVENTS.DEMO_PLAY_EVENT.demoPlaySetIp,
      ip,
    });
  };
}
export function demoPlayResetAction() {
  return (dispatch) => {
    dispatch({
      type: EVENTS.DEMO_PLAY_EVENT.demoPlayReset,
    });
  };
}

export function initalSetIp() {
  return (dispatch) => {
    getDataIp()
      .then((res) => {
        dispatch(demoPlaySetIp(res.IPv4));
      });
  };
}

export function checkLimitDemo(product) {
  return (dispatch, getState) => {
    const { DemoPlayReducer } = getState();
    const test = moment(DemoPlayReducer.data[product].rehitTime).add(1, 'day');
    if (moment(new Date()).isAfter(test)) {
      dispatch(demoPlaySetProductAction(
        {
          count: 1,
          rehitTime: new Date(),
        },
        product,
      ));
      return true;
    }

    if (DemoPlayReducer.data[product].count < 3) {
      dispatch(demoPlaySetProductAction(
        {
          count: DemoPlayReducer.data[product].count + 1,
          rehitTime: new Date(),
        },
        product,
      ));
      return true;
    }
    dispatch(demoPlaySetProductAction(
      {
        count: 3,
        rehitTime: new Date(),
      },
      product,
    ));
    return false;
  };
}
