import DMSansRegularFont from '../../../dist/fonts/dm-sans/DMSans-Regular.ttf';

const DMSansReguler = {
  fontFamily: 'dm-sans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    url(${DMSansRegularFont})
  `,
};

export default DMSansReguler;
