import React from 'react';

const RegisterScreen = React.lazy(() => import('../containers/RegisterScreen'));

export default [
  {
    component: RegisterScreen,
    path: '/register',
    exact: true,
  },
];
