import EVENTS from '../events';

const stateReducer = {
  isLoading: false,
  error: false,
  data: [],
};
function UserReducer(state = stateReducer, action) {
  switch (action.type) {
    case EVENTS.USER_EVENT.userPending:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case EVENTS.USER_EVENT.userFullfiled:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case EVENTS.USER_EVENT.userRejected:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case EVENTS.USER_EVENT.userReset:
      return {
        ...state,
        isLoading: false,
        data: [],
        error: true,
      };
    default:
      return state;
  }
}
export default UserReducer;
