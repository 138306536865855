import { makeStyles } from '@mui/styles';
import colors from '../../themes/colors';

const styles = makeStyles({
  linkStyles: {
    margin: '0 20px',
    padding: '10px 0',
    color: '#909090',
    '&.active, &.active + div.box .arrow, &:hover, &:hover + div.box .arrow, &:focus, &:focus + div.box .arrow': {
      color: `${colors.secondary} !important`,
    },
  },
  logo: {
    width: '100%',
    maxWidth: '150px',
    cursor: 'pointer',
    // padding: '10px 0 !important',
  },
  pointerStyles: {
    cursor: 'pointer',
  },
  popperTooltips: {
    background: '#F5F5F5',
    color: 'black',
  },
});

export default styles;
