import React from 'react';
import { SvgIcon } from '@mui/material';

const IcClose = (props) => <SvgIcon viewBox="0 0 24 24" {...props}>
  <svg width="24" height="24" viewBox="0 0 24 24"xmlns="http://www.w3.org/2000/svg">
    <path d="M18 6L6 18" stroke={props.htmlColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6 6L18 18" stroke={props.htmlColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
</SvgIcon>;

export default IcClose;
