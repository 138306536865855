import { Button, ThemeProvider } from '@mui/material';
import React from 'react';
import innerTheme from './theme';

const SecondaryButton = (muiButtonProps) => <>
    <ThemeProvider theme={(theme) => innerTheme(theme)}>
      <Button variant="outlined" {...muiButtonProps} />
    </ThemeProvider>
  </>;

export default SecondaryButton;
