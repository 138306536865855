import React from 'react';
import { SvgIcon } from '@mui/material';

const IcBurger = (props) => <><SvgIcon viewBox='0 0 24 24' {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M21 10H7" stroke={props.htmlColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M21 6H3" stroke={props.htmlColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M21 14H3" stroke={props.htmlColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M21 18H7" stroke={props.htmlColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  </SvgIcon></>;

export default IcBurger;
