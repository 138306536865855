import DemoReducer from './demoReducer';
import UserReducer from './userReducer';
import StatusPageReducer from './statusPageReducer';
import * as ProductReducer from './productReducer';
import DemoPlayReducer from './demoPlayReducer';
import VersionReducer from './versionReducer';
import * as FaqReducer from './faqReducer';

const REDUCERS = {
  DemoReducer,
  UserReducer,
  StatusPageReducer,
  DemoPlayReducer,
  VersionReducer,
  ...FaqReducer,
  ...ProductReducer,
};

export default REDUCERS;
