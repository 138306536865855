import DMSansBoldFont from '../../../dist/fonts/dm-sans/DMSans-Bold.ttf';

const DMSansBold = {
  fontFamily: 'dm-sans',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 800,
  src: `
    url(${DMSansBoldFont})
  `,
};

export default DMSansBold;
