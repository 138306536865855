import EVENTS from '../events';

const stateReducer = {
  version: 0,
};
function VersionReducer(state = stateReducer, action) {
  switch (action.type) {
    case EVENTS.VERSION_EVENT.versionSet:
      return { ...state, version: action.payload };
    default:
      return state;
  }
}
export default VersionReducer;
