import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  iconStyles: {
    transition: '.2s ease',
    '&.open': {
      transform: 'rotate(180deg)',
    },
  },
});

export default styles;
