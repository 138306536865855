import React from 'react';

const ServiceDesc = React.lazy(() => import('../containers/ServicesDescScreen'));

export default [
  {
    component: ServiceDesc,
    path: '/service-desc/object-detection',
    exact: true,
  },
  {
    component: ServiceDesc,
    path: '/service-desc/vehicle-counting',
    exact: true,
  },
  {
    component: ServiceDesc,
    path: '/service-desc/people-counting',
    exact: true,
  },
  {
    component: ServiceDesc,
    path: '/service-desc/crowd-detection',
    exact: true,
  },
  {
    component: ServiceDesc,
    path: '/service-desc/license-plate-recoognition',
    exact: true,
  },
  {
    component: ServiceDesc,
    path: '/service-desc/traffic-measurement',
    exact: true,
  },
];
