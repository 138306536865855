import API from '../../api';
import EVENTS from '../../redux/events';
import { resetStatusPage } from '../statusPageActions';

export function userPendingAction() {
  return (dispatch) => {
    dispatch({
      type: EVENTS.USER_EVENT.userPending,
    });
  };
}
export function userFullfiledAction(payload) {
  return (dispatch) => {
    dispatch({
      type: EVENTS.USER_EVENT.userFullfiled,
      payload,
    });
  };
}
export function userRejectedAction() {
  return (dispatch) => {
    dispatch({
      type: EVENTS.USER_EVENT.userRejected,
    });
  };
}
export function userResetAction() {
  return (dispatch) => {
    dispatch({
      type: EVENTS.USER_EVENT.userReset,
    });
  };
}

export function ActionUser(values) {
  return (dispatch) => new Promise((resolve, reject) => {
    dispatch(userPendingAction());
    API.AuthService.user(values)
      .then((res) => {
        dispatch(userFullfiledAction(res.data));
        resolve(res);
      })
      .catch((err) => {
        dispatch(userRejectedAction());
        reject(err);
      });
  });
}

export function logoutAction(values) {
  return (dispatch) => new Promise((resolve, reject) => {
    API.AuthService.logout(values)
      .then((res) => {
        dispatch(resetStatusPage());
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
