import EVENTS from '../../redux/events';

export function setStatusPage(status) {
  return (dispatch) => {
    dispatch({
      type: EVENTS.STATUS_PAGE_EVENT.tokenSet,
      payload: status,
    });
  };
}
export function setDataPage(data) {
  return (dispatch) => {
    dispatch({
      type: EVENTS.STATUS_PAGE_EVENT.dataSet,
      payload: data,
    });
  };
}
export function resetStatusPage() {
  return (dispatch) => {
    dispatch({
      type: EVENTS.STATUS_PAGE_EVENT.reset,
    });
  };
}
