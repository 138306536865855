/* eslint-disable react/prop-types */
import { KeyboardArrowDown } from '@mui/icons-material';
import {
  Box,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Divider,
} from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';
import styles from './style';

const Dropdown = ({
  children,
  data = [],
  withoutArrow = false,
  withDevide = false,
}) => {
  const classes = styles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const handleClose = useCallback(() => {
    setOpen(false);
    window.removeEventListener('scroll', () => {});
  }, []);
  const handleOpen = useCallback(() => {
    setOpen(true);
    window.addEventListener('scroll', handleClose);
  }, [handleClose]);
  return <>
    <div className={classes.wrapper} >
      <div ref={anchorRef} onClick={handleOpen}>
        <Box
          position="relative"
          className={`${open ? 'active' : ''}`}
        >
          {children}
          {
            !withoutArrow && (
              <Box
                position="absolute"
                top="9px"
                right="-5px"
                className="box"
              >
                <KeyboardArrowDown color="inherit" className={`${classes.iconStyles} ${open ? 'open' : ''} arrow`}/>
              </Box>
            )
          }
        </Box>
      </div>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-start" transition disablePortal>
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          style={{ transformOrigin: 'center top' }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList autoFocusItem={open} id="menu-list-grow" >
                {
                  data.map((da, i) => (
                    <>
                      <MenuItem key={i} onClick={da.onClick} className="item">{da.text}</MenuItem>
                      {
                        (i !== data.length - 1 && withDevide) && <Divider />
                      }
                    </>
                  ))
                }
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
    </div>
  </>;
};

export default Dropdown;
