/**
 * @var WHITE_LIST is variable to declare store reducer save in browser;
 */
const WHITE_LIST = [
  'StatusPageReducer',
  'DemoPlayReducer',
  'VersionReducer',
];
const WHITE_LIST_REDUCER = [
  'StatusPageReducer',
  'DemoPlayReducer',
  'VersionReducer',
];

export default {
  WHITE_LIST,
  WHITE_LIST_REDUCER,
};
