import React from 'react';

const FaqScreen = React.lazy(() => import('../containers/FaqScreen'));

export default [
  {
    component: FaqScreen,
    path: '/faq',
    exact: true,
  },
];
