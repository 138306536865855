import EVENTS from '../events';

const stateReducer = {
  token: false,
  data: {},
};
function StatusPageReducer(state = stateReducer, action) {
  switch (action.type) {
    case EVENTS.STATUS_PAGE_EVENT.tokenSet:
      return { ...state, token: action.payload };
    case EVENTS.STATUS_PAGE_EVENT.dataSet:
      return {
        ...state,
        data: action.payload,
      };
    case EVENTS.STATUS_PAGE_EVENT.tokenRemove:
      return { ...state, token: false };
    case EVENTS.STATUS_PAGE_EVENT.reset:
      return stateReducer;
    default:
      return state;
  }
}
export default StatusPageReducer;
