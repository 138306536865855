import ImageProcessingService from './services/ImageProcessingService';
import AuthService from './services/AuthService';
import ProductService from './services/ProductService';
import ContactUsService from './services/ContactUsService';
import FaqService from './services/FaqService';

const API = {
  ImageProcessing: { ...ImageProcessingService },
  AuthService,
  ProductService,
  ContactUsService,
  FaqService,
};

export default API;
