import { createTheme } from '@mui/material';

const innerTheme = (theme) => createTheme({
  ...theme,
  components: {
    ...theme.components,
    MuiButton: {
      ...theme.components.MuiButton,
      styleOverrides: {
        ...theme.components.MuiButton.styleOverrides,
        outlinedPrimary: {
          background: `${theme.palette.primary.main}74`,
          textTransform: 'none',
          color: 'white',
          borderWidth: '2px !important',
          '&:hover': {
            background: `${theme.palette.primary.main}80 !important`,
          },
        },
      },
    },
  },
});

export default innerTheme;
