import { ArrowBack } from '@mui/icons-material';
import { Box, Button, Container } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Img404 from '../../dist/images/background/404.png';

function Component() {
  const history = useHistory();
  return <>
    <Navbar defaultStatic={true} />
    <Container maxWidth="lg" sx={{ paddingY: '60px' }}>
      <Box marginBottom={'10px'}>
        <Button onClick={() => history.replace('/')} color="inherit">
          <ArrowBack/>
        </Button>
      </Box>
      <Box display='flex' alignItems={'center'} justifyContent='center'>
        <img loading="lazy" src={Img404} alt="" />
      </Box>
    </Container>
  </>;
}

export default Component;
