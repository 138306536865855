export default [
  {
    path: '/',
    property: 'Home',
    type: 'page',
  },
  {
    path: '/verification-account',
    property: 'doVerification',
    type: 'event',
  },
  {
    path: '/login',
    property: 'Login',
    type: 'page',
  },
  {
    path: '/register',
    property: 'Register',
    type: 'page',
  },
  {
    path: '/service-desc/object-detection',
    property: 'Service Description - Object Detection',
    type: 'page',
  },
  {
    path: '/service-desc/vehicle-counting',
    property: 'Service Description - Vehicle Counting',
    type: 'page',
  },
  {
    path: '/service-desc/people-counting',
    property: 'Service Description - People Counting',
    type: 'page',
  },
  {
    path: '/service-desc/crowd-detection',
    property: 'Service Description - Crowd Detection',
    type: 'page',
  },
  {
    path: '/service-desc/license-plate-recoognition',
    property: 'Service Description - License Plate Recoognition',
    type: 'page',
  },
  {
    path: '/service-desc/traffic-measurement',
    property: 'Service Description - Traffic Measurement',
    type: 'page',
  },
  {
    path: '/dasboard/pricing',
    property: 'Service Description - Pricing',
    type: 'page',
  },
  {
    path: '/dasboard/api-and-service',
    property: 'Dashboard - API and Service',
    type: 'page',
  },
  {
    path: '/dasboard/billing-dashboard',
    property: 'Dashboard - Biling Dashboard',
    type: 'page',
  },
  {
    path: '/dasboard/documentation',
    property: 'Dashboard - Documentation',
    type: 'page',
  },
  {
    path: '/dasboard/setting-account',
    property: 'Dashboard - Setting Account',
    type: 'page',
  },
  {
    path: '/contact-us',
    property: 'Contact Us',
    type: 'page',
  },
  {
    path: '/faq',
    property: 'FAQ',
    type: 'page',
  },
  {
    path: '/404',
    property: 'Error',
    type: 'page',
  },
];
