import { Button, ThemeProvider } from '@mui/material';
import React from 'react';
import innerTheme from './theme';

const PrimaryFilter = ({ ...muiButtonProps }) => <>
    <ThemeProvider theme={(theme) => innerTheme(theme)}>
      <Button variant="contained" {...muiButtonProps} />
    </ThemeProvider>
  </>;

export default PrimaryFilter;
