import { makeStyles } from '@mui/styles';

/* eslint-disable import/no-extraneous-dependencies */
const styles = makeStyles({
  wrapperStyle: {
    background: 'conic-gradient(from 90deg at 50% 50%, #C4C4C4 0deg, #BF303D 0.04deg, #FFF0F1 360deg)',
    borderRadius: '999999px',
    position: 'relative',
    overflow: 'hidden',
    animation: 'spinner 0.8s infinite linear',
  },
  wrapperCircle: {
    background: 'white',
    borderRadius: '999999px',
    position: 'absolute',
    top: '25%',
    bottom: '25%',
    right: '25%',
    left: '25%',
  },
  circle: {
    background: '#BF303D',
    left: '75%',
    bottom: '38%',
    position: 'absolute',
    top: '38%',
    borderRadius: '999999px',
    right: 0,
  },
});

export default styles;
