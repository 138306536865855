import EVENTS from '../events';

const stateReducer = {
  isLoading: false,
  error: false,
  data: {
    od: {},
    vc: {},
    cd: {},
  },
};
function DemoReducer(state = stateReducer, action) {
  switch (action.type) {
    case EVENTS.DEMO_EVENT.demoPending:
      return {
        ...state,
        isLoading: true,
        data: { ...state.data, [action.name]: {} },
        error: false,
      };
    case EVENTS.DEMO_EVENT.demoFullfiled:
      return {
        ...state,
        isLoading: false,
        data: { ...state.data, [action.name]: action.payload },
      };
    case EVENTS.DEMO_EVENT.demoRejected:
      return {
        ...state,
        isLoading: false,
        data: { ...state.data, [action.name]: {} },
        error: true,
      };
    case EVENTS.DEMO_EVENT.demoReset:
      return {
        ...state,
        isLoading: false,
        data: { ...state.data, [action.name]: {} },
        error: true,
      };
    default:
      return state;
  }
}
export default DemoReducer;
